import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { Multiselect } from 'multiselect-react-dropdown'

const TagFilter = ({ selectedTags, setSelectedTags, handleSearch }) => {
  const tags = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark {
          distinct(field: frontmatter___tags)
        }
      }
    `
  )

  let selectedTagOptions = selectedTags.map((tag) => {
    return {
      label: tag,
      value: tag,
    }
  })

  const [tagOptions] = useState(
    tags.allMarkdownRemark.distinct.map((tag) => {
      return {
        value: tag,
        label: tag,
      }
    })
  )

  const onSelect = (selectedList, selectedItem) => {
    let array = selectedTags
    array.push(selectedItem.value)
    selectedTagOptions.push({
      label: selectedItem.label,
      value: selectedItem.value,
    })
    setSelectedTags(array)
    handleSearch()
  }

  const onRemove = (selectedList, selectedItem) => {
    let array = selectedTags
    const index = array.indexOf(selectedItem.value)
    if (index !== -1) {
      array.splice(index, 1)
      selectedTagOptions.splice(index, 1)
      setSelectedTags(array)
      handleSearch()
    }
  }

  return (
    <div className="accordion-wrapper">
      <Accordion
        allowZeroExpanded={true}
        preExpanded={['30544134-ec6c-11ec-8ea0-0242ac120002']}
      >
        <AccordionItem uuid="30544134-ec6c-11ec-8ea0-0242ac120002">
          <AccordionItemHeading>
            <AccordionItemButton>Tags</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className="accordion-tags-panel">
            <Multiselect
              options={tagOptions}
              selectedValues={selectedTagOptions}
              onSelect={onSelect}
              onRemove={onRemove}
              displayValue="label"
              closeIcon={'cancel'}
              style={{
                multiselectContainer: {
                  listStyleType: 'none',
                  flexWrap: 'wrap',
                },
                searchWrapper: {
                  height: 'auto',
                  flexWrap: 'wrap',
                },
                searchBox: {
                  border: 'none',
                  borderRadius: '0px',
                },
                chips: {
                  background: '#f4f4f4',
                  color: 'black',
                },
                optionContainer: {
                  border: 'none',
                },
                option: {
                  background: '#f4f4f4',
                  color: 'black',
                  marginLeft: '0px',
                  listStyleType: 'none',
                  fontSize: '13px',
                },
              }}
            />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  )
}

export default TagFilter
