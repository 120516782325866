import { Link } from 'gatsby'
import React from 'react'

const SearchTiles = ({ tiles }) => {
  const tutorials = tiles.filter(
    (content_item) =>
      content_item.frontmatter.content_type === 'tutorial' ||
      content_item.frontmatter.content_type === 'quickstart'
  )
  const learningPaths = tiles.filter(
    (content_item) => content_item.frontmatter.content_type === 'learn'
  )
  return (
    <>
      <div>
        <div className="grid-tiles-container-search">
          {tutorials.map((tile, idx) => {
            const key = `${tile.frontmatter.path.substring(1)}-${idx + 1}`
            return (
              <Link
                to={`${tile.frontmatter.path}`}
                key={key}
                className="inline-block"
              >
                <div
                  className={`grid-tile-item--${tile.frontmatter.content_type}`}
                >
                  <div
                    className={`tile-item--title--${tile.frontmatter.content_type}`}
                  >
                    {tile.frontmatter.title}
                  </div>
                  <hr />
                  <div
                    className={`tile-item-description--${tile.frontmatter.content_type}`}
                  >
                    <ul>
                      {tile.frontmatter.description.map(
                        (descriptionBullet, idx) => {
                          return <li key={idx}>{descriptionBullet}</li>
                        }
                      )}
                    </ul>
                  </div>
                  <hr />
                  <div
                    className={`tile-item--tags--${tile.frontmatter.content_type}`}
                  >
                    {tile.frontmatter.tags.map((value, idx) => (
                      <div
                        key={idx}
                        className={`tags--tag--${tile.frontmatter.content_type}`}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            )
          })}
          {learningPaths.map((tile, idx) => {
            const key = `${tile.frontmatter.path.substring(1)}-${idx + 1}`
            return (
              <Link
                to={`${tile.frontmatter.path}`}
                key={key}
                className="inline-block"
              >
                <div
                  className={`grid-tile-item--${tile.frontmatter.content_type}`}
                >
                  <div
                    className={`tile-item--title--${tile.frontmatter.content_type}`}
                  >
                    {tile.frontmatter.title}
                  </div>
                  <hr />
                  <div
                    className={`tile-item-description--${tile.frontmatter.content_type}`}
                  >
                    <ul>
                      {tile.frontmatter.description.map(
                        (descriptionBullet, idx) => {
                          return <li key={idx}>{descriptionBullet}</li>
                        }
                      )}
                    </ul>
                  </div>
                  <hr />
                  <div
                    className={`tile-item--tags--${tile.frontmatter.content_type}`}
                  >
                    {tile.frontmatter.tags.map((value, idx) => (
                      <div
                        key={idx}
                        className={`tags--tag--${tile.frontmatter.content_type}`}
                      >
                        {value}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SearchTiles
