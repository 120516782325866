import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { useFlexSearch } from 'react-use-flexsearch'
import { FaSearch, FaBars, FaMap, FaBolt, FaBookOpen } from 'react-icons/fa'
import { NumberParam, StringParam, useQueryParam } from 'use-query-params'
import { navigate } from 'gatsby'
import LanguageFilter from './components/LanguageFilter'
import TagFilter from './components/TagFilter'
import TechnologyFilter from './components/TechnologyFilter'
import SearchTiles from './components/SearchTiles'
import ReactPaginate from 'react-paginate'
import Select from 'react-select'
import '../styles/modules/search.scss'

const Search = ({ data }) => {
  const index = data.localSearchContent.index
  const store = data.localSearchContent.store
  const [query] = useQueryParam('query', StringParam)
  const [input, setInput] = useState(query ? query : '')
  const [currentPage, setCurrentPage] = useState(0)
  const [per] = useQueryParam('per', NumberParam)
  const [perPage, setPerPage] = useState(
    per
      ? { label: `${per} per page`, value: per }
      : { label: '9 per page', value: 9 }
  )

  const [fil] = useQueryParam('fil', StringParam)
  const [filter, setFilter] = useState(fil ? fil : 'all')

  const [pageOptions] = useState([
    { value: 9, label: '9 per page' },
    { value: 18, label: '18 per page' },
    { value: 27, label: '27 per page' },
    { value: 36, label: '36 per page' },
  ])

  let results = useFlexSearch(query, index, store)

  const [languages] = useQueryParam('languages', StringParam)
  const [selectedLanguages, setSelectedLanguages] = useState(
    languages ? languages.split(',') : []
  )

  const [technology] = useQueryParam('technology', StringParam)
  const [selectedTechnology, setSelectedTechnology] = useState(
    technology ? technology : ''
  )

  const [tags] = useQueryParam('tags', StringParam)
  const [selectedTags, setSelectedTags] = useState(tags ? tags.split(',') : [])

  const handlePaging = (selectedPage) => {
    setCurrentPage(selectedPage.selected)
  }

  let content = query
    ? results.map((result) => {
        const {
          path,
          filter,
          description,
          title,
          tags,
          length,
          content_type,
          sdk_language,
          technology,
        } = result
        return {
          frontmatter: {
            path,
            filter,
            description,
            title,
            tags,
            length,
            content_type,
            sdk_language,
            technology,
          },
        }
      })
    : data.allMarkdownRemark.nodes

  if (selectedLanguages.length > 0) {
    content = content.filter((content_item) => {
      if (content_item.frontmatter.sdk_language !== null) {
        return (
          content_item.frontmatter.sdk_language.filter((value) =>
            selectedLanguages.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  if (selectedTags.length > 0) {
    content = content.filter((content_item) => {
      if (content_item.frontmatter.tags !== null) {
        return (
          content_item.frontmatter.tags.filter((value) =>
            selectedTags.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  let tech = content.map((content) => content.frontmatter.technology)
  const contentLength = content.length

  if (selectedTechnology.length > 0) {
    content = content.filter((content_item) => {
      if (content_item.frontmatter.technology !== null) {
        return (
          content_item.frontmatter.technology.filter((value) =>
            selectedTechnology.includes(value)
          ).length > 0
        )
      }
      return ''
    })
  }

  let filteredContent = content

  if (filter !== 'all') {
    filteredContent = content.filter(
      (content_item) => content_item.frontmatter.content_type === filter
    )
  }

  let pageCount = Math.ceil(filteredContent.length / perPage.value)
  let pageContent = filteredContent.slice(
    currentPage * perPage.value,
    (currentPage + 1) * perPage.value
  )

  const handlePerPage = (e) => {
    setPerPage(e)
    handleSearch()
  }

  const handleFilter = (e) => {
    setFilter(e)
    handleSearch()
  }

  const handleSearch = () => {
    setCurrentPage(0)
    const selectedLanguageParams = selectedLanguages.map((language) =>
      language.trim().replace(' ', '+')
    )
    const selectedTagParams = selectedTags.map((tag) =>
      tag.trim().replace(' ', '+')
    )
    const selectedTechnologyParams = selectedTechnology.trim().replace(' ', '+')
    navigate(
      `/search?query=${input
        .trim()
        .replace(' ', '+')}&languages=${selectedLanguageParams.join(
        ','
      )}&tags=${selectedTagParams.join(
        ','
      )}&technology=${selectedTechnologyParams}&per=${
        perPage.value
      }&fil=${filter}`
    )
  }

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  const tutorials = content.filter(
    (content_item) => content_item.frontmatter.content_type === 'tutorial'
  )
  const learningPaths = content.filter(
    (content_item) => content_item.frontmatter.content_type === 'learn'
  )
  const quickstartGuides = content.filter(
    (content_item) => content_item.frontmatter.content_type === 'quickstart'
  )

  return (
    <div className="page--search wrapper">
      <Helmet title={`Search | Couchbase Developer Portal`} />
      <div className="search-wrapper">
        <FaSearch className="search-icon" />
        <input
          className="search-input"
          placeholder="Search"
          name="input"
          value={input}
          onChange={(event) => setInput(event.target.value)}
          onKeyDown={handleKeyPress}
        />
        <button className="search-button" onClick={handleSearch}>
          Search
        </button>
      </div>
      {query && content.length > 0 && (
        <div className="overflow-x-scroll md:overflow-x-auto whitespace-nowrap md:whitespace-normal">
          <button
            className="filter-button"
            onClick={() => handleFilter('all')}
            style={{
              borderColor: filter === 'all' ? '#1E88E5' : '#f4f4f4',
              borderWidth: 'thin',
            }}
          >
            <FaBars />
            <div style={{ margin: '10px' }}>
              All Content{' '}
              <span className="technology-count">{content.length}</span>
            </div>
          </button>
          <button
            className="filter-button"
            onClick={() => handleFilter('tutorial')}
            style={{
              borderColor: filter === 'tutorial' ? '#1E88E5' : '#f4f4f4',
              borderWidth: 'thin',
            }}
          >
            <FaBookOpen />
            <div style={{ margin: '10px' }}>
              Tutorials{' '}
              <span className="technology-count">{tutorials.length}</span>
            </div>
          </button>
          <button
            className="filter-button"
            onClick={() => handleFilter('learn')}
            style={{
              borderColor: filter === 'learn' ? '#1E88E5' : '#f4f4f4',
              borderWidth: 'thin',
            }}
          >
            <FaMap />
            <div style={{ margin: '10px' }}>
              Learning Paths{' '}
              <span className="technology-count">{learningPaths.length}</span>
            </div>
          </button>
          <button
            className="filter-button"
            onClick={() => handleFilter('quickstart')}
            style={{
              borderColor: filter === 'quickstart' ? '#1E88E5' : '#f4f4f4',
              borderWidth: 'thin',
            }}
          >
            <FaBolt />
            <div style={{ margin: '10px' }}>
              {' '}
              Quickstart Guides{' '}
              <span className="technology-count">
                {quickstartGuides.length}
              </span>
            </div>
          </button>
        </div>
      )}
      {query && (
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/5 filter-accordions">
            <LanguageFilter
              selectedLanguages={selectedLanguages}
              setSelectedLanguages={setSelectedLanguages}
              handleSearch={handleSearch}
            />
            <TagFilter
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              handleSearch={handleSearch}
            />
            <TechnologyFilter
              selectedTechnology={selectedTechnology}
              setSelectedTechnology={setSelectedTechnology}
              tech={tech}
              contentLength={contentLength}
              handleSearch={handleSearch}
            />
          </div>
          <div className="w-full lg:w-4/5">
            <SearchTiles tiles={pageContent} />
            {query && content.length > 0 && (
              <div className="search-pagination flex">
                <ReactPaginate
                  previousLabel="< prev"
                  nextLabel="next >"
                  breakLabel="..."
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={handlePaging}
                  forcePage={currentPage}
                  containerClassName="pagination"
                  previousClassName="previous whitespace-nowrap md:whitespace-normal"
                  previousLinkClassName="previousLink"
                  breakClassName="break"
                  breakLinkClassName="breakLink"
                  pageClassName="page"
                  pageLinkClassName="pageLink"
                  nextClassName="next whitespace-nowrap md:whitespace-normal"
                  nextLinkClassName="nextLink"
                  hrefBuilder={() => `#`}
                />
                <div className="per-page">
                  <Select
                    value={perPage}
                    onChange={handlePerPage}
                    options={pageOptions}
                    menuPlacement="top"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {!query && (
        <div className="empty-search-wrapper">
          <FaSearch style={{ height: '50px', width: '50px' }} />
          <div>Search above to find Tutorials, Learning Paths,</div>
          <div>and Quickstart Guides!</div>
        </div>
      )}
    </div>
  )
}

export default Search

export const query = graphql`
  query {
    localSearchContent {
      index
      store
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          content_type: { in: ["learn", "tutorial", "quickstart"] }
        }
      }
    ) {
      nodes {
        frontmatter {
          content_type
          path
          short_title
          description
          filter
          tags
          title
          length
          sdk_language
          technology
        }
        id
        rawMarkdownBody
      }
    }
  }
`
